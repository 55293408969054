import { html, actuallyEscapeHTML } from './util';

const projects = [
  {
    name: 'Cathy Ray\'s Tubular Training',
    description: 'Rhythm-based bullet hell featuring a silly lil robot. Originally made for <a href="https://itch.io/jam/mini-jam-125-signal" target="_blank" rel="noopener">Mini Jam 125: Signal</a>.',
    date: new Date(2023, 1, 6),
    dateVisual: 'Feburary, ongoing',
    links: [{
      type: 'itch',
      url: 'https://oatmealine.itch.io/cathy-rays-tubular-training',
    }],
  },
  {
    name: 'GMod Title Screen',
    description: 'Transforms the usual Minecraft title screen into one that looks highly reminiscent of Garry\'s Mod\'s title screen.',
    date: new Date(2022, 10, 8),
    links: [{
      type: 'modrinth',
      url: 'https://modrinth.com/mod/gmod-title-screen',
    }, {
      type: 'curseforge',
      url: 'https://legacy.curseforge.com/minecraft/mc-mods/gmod-title-screen',
    }, {
      type: 'git',
      url: 'https://git.oat.zone/oat/gmod-title-screen/src/branch/1.20.1'
    }],
  },
  {
    name: 'Super Secret Revival',
    description: 'Faithfully reimplements the Super Secret Settings button that was added in 13w38a (1.7.2) and removed in 15w31a (1.9) to modern versions of Minecraft.',
    date: new Date(2022, 10, 2),
    links: [{
      type: 'modrinth',
      url: 'https://modrinth.com/mod/super-secret-revival',
    }, {
      type: 'curseforge',
      url: 'https://legacy.curseforge.com/minecraft/mc-mods/fabric-super-secret-settings',
    }, {
      type: 'git',
      url: 'https://git.oat.zone/oat/super-secret-revival/src/branch/1.20.1',
    }],
  },
  {
    name: 'cohost-blogger',
    description: 'A self-hostable CMS-like proxying Cohost posts',
    date: new Date(2023, 3, 30),
    links: [{
      type: 'blog',
      url: 'https://blog.oat.zone/cohost-blogger',
    }, {
      type: 'git',
      url: 'https://git.oat.zone/oat/cohost-blogger',
    }, {
      type: 'site',
      url: 'https://blog.oat.zone',
    }]
  },
  {
    name: 'funfriend',
    description: 'corru.observer desktop buddy',
    date: new Date(2023, 4, 4),
    links: [{
      type: 'git',
      url: 'https://git.oat.zone/oat/funfriend',
    }],
  },
  {
    name: 'Uranium Template',
    description: 'A Love2D-like NotITG game development template that\'ll make you feel like you\'re not ever dealing with Stepmania jank at all',
    date: new Date(2022, 8, 25),
    dateVisual: 'September, maintaining since',
    links: [{
      type: 'git',
      url: 'https://git.oat.zone/oat/uranium-template'
    }]
  },
  {
    name: 'crystal-gauntlet',
    description: 'A Geometry Dash server reimplementation in Crystal, focusing on speed and 1:1 recreations of vanilla GD features',
    date: new Date(2022, 11, 30),
    dateVisual: 'December, ongoing / on hold',
    links: [{
      type: 'git',
      url: 'https://git.oat.zone/oat/crystal-gauntlet',
    }],
  },
  {
    name: 'oat.zone',
    description: 'The very site you\'re looking at!',
    lore: 'This site went through <em>numerous</em> redesigns before landing on this one, and it might go through many more',
    date: new Date(2022, 3, 20),
    dateVisual: 'May, ongoing',
    links: [{
      type: 'site',
      url: 'https://oat.zone',
    }],
  },
  {
    name: 'Essential reverse engineering',
    description: 'Reverse-engineering project of a Minecraft mod and associated WebSocket service',
    date: new Date(2022, 3, 13),
    links: [{
      type: 'blog',
      url: 'https://blog.oat.zone/reverse-engineering-minecraft-cosmetics/',
    }, {
      type: 'git',
      url: 'https://git.oat.zone/oat/essential-re',
    }]
  },
  {
    name: 'deemix-web-frontend',
    description: 'A simple frontend for Deemix',
    date: new Date(2021, 9, 28),
    links: [{
      type: 'blog',
      url: 'https://blog.oat.zone/you-wouldnt-download-a-car/',
    }, {
      type: 'git',
      url: 'https://git.oat.zone/oat/deemix-web-frontend',
    }],
  },
  {
    name: 'Starsyrup: Palette Pals',
    description: 'A heckof sweet lil game wherein you take care of cool pets!',
    date: new Date(2022, 2, 7),
    links: [{
      type: 'itch',
      url: 'https://maggiemagius.itch.io/starsyrup',
    }],
  },
  {
    name: 'wordle-hs',
    description: 'A wordle clone in Haskell',
    date: new Date(2022, 6, 31),
    links: [{
      type: 'git',
      url: 'https://git.oat.zone/oat/wordle-hs',
    }],
  },
  {
    name: 'modfiles.oat.zone',
    description: 'A modfile collection display',
    date: new Date(2022, 0, 2),
    links: [{
      type: 'git',
      url: 'https://git.oat.zone/oat/oat-zone-modfiles',
    }, {
      type: 'site',
      url: 'https://modfiles.oat.zone',
    }],
  },
  {
    name: 'cumstorm',
    description: 'A Geometry Dash private server written in Typescript using MongoDB',
    date: new Date(2020, 0, 24),
    dateVisual: 'January, abandoned',
    links: [{
      type: 'git',
      url: 'https://git.oat.zone/oat/cumstorm',
    }],
  },
  {
    name: 'Jillo',
    description: 'Private discord bot managing pronouns, colors, and miscellaneous fun stuff',
    date: new Date(2022, 3, 3),
    dateVisual: 'April, ongoing',
    links: [{
      type: 'git',
      url: 'https://git.oat.zone/dark-firepit/jillo-bot',
    }],
  },
  {
    name: 'dark-firepit.cloud',
    description: 'Site made for the dark-firepit friend group',
    date: new Date(2022, 10, 16),
    dateVisual: 'November, maintaining since',
    links: [{
      type: 'site',
      url: 'https://dark-firepit.cloud',
    }, {
      type: 'git',
      url: 'https://git.oat.zone/dark-firepit/dark-firepit.cloud',
    }],
  },
  {
    name: 'yugoslavia.best',
    description: 'home of ㍲',
    date: new Date(2021, 11, 16),
    dateVisual: 'December, ongoing, for better or for worse',
    links: [{
      type: 'site',
      url: 'https://yugoslavia.best',
    }, {
      type: 'site',
      url: 'https://yugoslavia.fishing',
    }],
  },
  {
    name: 'eyes in the water',
    description: 'NotITG modfile',
    date: new Date(2022, 9, 13),
    links: [{
      type: 'video',
      url: 'https://www.youtube.com/watch?v=avNF21NRe10',
    }],
  },
  {
    name: 'SIMPLY LOVE (Oat vs Rya\'s Fork)',
    description: 'A fork of NotITG\'s Simply Love with more features, different graphics, theming and aesethetics.',
    date: new Date(2021, 2, 14),
    links: [{
      type: 'git',
      url: 'https://github.com/oatmealine/simply-love-oat-fork',
    }],
  },
  {
    name: 'scrunkly',
    description: 'Visual novel, dialog and overall sequential text processing engine.',
    date: new Date(2023, 1, 18),
    links: [{
      type: 'git',
      url: 'https://github.com/oatmealine/scrunkly',
    }],
  },
  {
    name: 'gd-icon-renderer',
    description: 'A Crystal Geometry Dash icon renderer library',
    date: new Date(2023, 0, 12),
    links: [{
      type: 'git',
      url: 'https://github.com/oatmealine/gd-icon-renderer',
    }, {
      type: 'site',
      url: 'https://gdicon.oat.zone/index.html',
    }],
  },
  {
    name: 'powercord-tiktok-tts',
    description: 'Lets you speak Discord messages with the TikTok TTS',
    date: new Date(2022, 3, 28),
    dateVisual: 'April, abandoned',
    links: [{
      type: 'git',
      url: 'https://github.com/oatmealine/powercord-tiktok-tts',
    }],
  },
  {
    name: 'WanderComposer',
    description: 'A level editor for Wandersong',
    date: new Date(2019, 1, 25),
    links: [{
      type: 'git',
      url: 'https://github.com/oatmealine/wandercomposer',
    }],
  },
  {
    name: 'cumsystem',
    description: 'A simple and powerful Discord.JS command system',
    date: new Date(2020, 2, 5),
    links: [{
      type: 'git',
      url: 'https://github.com/oatmealine/cumsystem',
    }],
  },
  {
    name: 'puyo.rocks',
    description: 'Sort of a previous version of oat.zone, also a personal site but made before the oatmealine alias',
    date: new Date(2018, 10, 15),
    dateVisual: 'November 2018 - July 2021',
    links: [{
      type: 'git',
      url: 'https://github.com/oatmealine/puyo.rocks',
    }],
  },
  {
    name: 'Boteline',
    description: 'Yet another swiss-knife utility/moderation/fun Discord bot',
    date: new Date(2019, 7, 18),
    dateVisual: 'August 2019 - July 2021',
    links: [{
      type: 'git',
      url: 'https://github.com/oatmealine/boteline',
    }]
  },
  {
    name: 'DeathMarkers',
    description: 'A small little everest mod that shows markers at places where you\'ve died',
    date: new Date(2019, 6, 10),
    links: [{
      type: 'git',
      url: 'https://github.com/oatmealine/DeathMarkers',
    }, {
      type: 'site',
      url: 'https://gamebanana.com/mods/53649',
    }],
  },
  {
    name: '78',
    description: 'An esolang focused on the numbers 7 and 8',
    date: new Date(2020, 3, 7),
    links: [{
      type: 'git',
      url: 'https://github.com/oatmealine/78',
    }],
  },
  {
    name: 'purplewaffle',
    description: 'A Discord.JS command system',
    date: new Date(2018, 11, 3),
    dateVisual: 'December, abandoned',
    links: [{
      type: 'git',
      url: 'https://github.com/oatmealine/purplewaffle',
    }],
  },
  {
    name: 'decrypt',
    description: 'A short simple encryption/decryption system with passwords based off of Hacknet\'s decrypt system',
    date: new Date(2019, 1, 8),
    links: [{
      type: 'git',
      url: 'https://github.com/oatmealine/decrypt',
    }],
  },
  {
    name: 'carl',
    description: 'A short platformer-shooter hybrid project',
    date: new Date(2019, 9, 28),
    dateVisual: 'October, abandoned',
    links: [{
      type: 'git',
      url: 'https://github.com/oatmealine/carl',
    }],
  },
  {
    name: 'bulet heck!',
    description: 'a love2d bullet hell with little to 0 presentation',
    date: new Date(2019, 7, 18),
    dateVisual: 'August, abandoned',
    links: [{
      type: 'git',
      url: 'https://github.com/oatmealine/bulet-heck',
    }],
  },
  {
    name: 'MoreEntities',
    description: 'A plugin for <a href="https://github.com/CelestialCartographers/Ahorn" target="_blank" rel="noopener">Ahorn</a> that adds more entites, triggers, etc to it',
    date: new Date(2019, 0, 31),
    links: [{
      type: 'git',
      url: 'https://github.com/oatmealine/MoreEntities',
    }],
  },
  {
    name: 'julibox.tv',
    description: 'Jackbox proxy server for easy-to-use client modifications',
    date: new Date(2023, 9, 4),
    links: [{
      type: 'git',
      url: 'https://github.com/femboyindustries/julibox.tv',
    }],
  },
  {
    name: 'bab be u',
    description: 'Love2D silly fangame of Baba Is You',
    date: new Date(2019, 3, 17),
    dateVisual: 'May 2019 - December 2020',
    links: [{
      type: 'git',
      url: 'https://github.com/lilybeevee/bab-be-u',
    }],
  },
  {
    name: 'lovetris',
    description: 'Tetris styled game made in Love2D',
    date: new Date(2018, 7, 5),
    dateVisual: 'August, abandoned',
    links: [{
      type: 'git',
      url: 'https://github.com/TeamCerealCode/lovetris',
    }],
  },
  {
    name: 'Doki Doki Repentance',
    description: 'Isaac mod that adds DDLC characters as playable characters. I worked on the Repentance port and tainted characters!',
    date: new Date(2021, 9, 28),
    links: [{
      type: 'steam',
      url: 'https://steamcommunity.com/sharedfiles/filedetails/?id=2638699710',
    }, {
      type: 'blog',
      url: 'https://blog.oat.zone/that-time-i-worked-on-an-isaac-ddlc-mod',
    }],
  },
  {
    name: 'Dream in Binding of Isaac',
    description: 'Isaac mod that adds Dream as a playable character. Joke turned into full project',
    date: new Date(2021, 4, 16),
    links: [{
      type: 'steam',
      url: 'https://steamcommunity.com/sharedfiles/filedetails/?id=2488581980',
    }],
  },
  {
    name: 'Box of Eases',
    description: 'A Love2D application to view, create and mix ease functions',
    date: new Date(2021, 8, 17),
    links: [{
      type: 'git',
      url: 'https://gitdab.com/oat/box-of-eases',
    }],
  },
  {
    name: 'bouncy',
    description: 'Tiny script that hooks onto MPRIS to read your currently listening song and displays it in a nice way',
    date: new Date(2021, 8, 2),
    links: [{
      type: 'git',
      url: 'https://gitdab.com/oat/bouncy',
    }],
  },
  {
    name: 'notsanequarium',
    description: 'A remake of Insaniquarium, the game published by PopCap in 2004',
    date: new Date(2021, 0, 22),
    links: [{
      type: 'git',
      url: 'https://gitdab.com/oat/notsanequarium',
    }, {
      type: 'site',
      url: 'https://yugoslavia.fishing'
    }],
  },
  {
    name: 'Markdown PLUS',
    description: 'An "alternative" to Markdown for Cohost which lets you use fucked-up text in your everyday posts',
    date: new Date(2022, 10, 18),
    links: [{
      type: 'site',
      url: 'https://oat.zone/markdown-plus',
    }],
  },
  {
    name: 'Mine in Abyss',
    description: 'Community-run survival Minecraft server based on the Made in Abyss anime. I do game design work here!',
    date: new Date(2024, 6, 23),
    dateVisual: 'July, ongoing',
    links: [{
      type: 'site',
      url: 'https://mineinabyss.com',
    }],
  },
  {
    name: 'Egg Related Videogame',
    description: 'A game based on hatching eggs in order to collect creatures. Originally made for <a href="https://itch.io/jam/love2d-jam-2024" target="_blank" rel="noopener">LÖVE Jam 2024</a>.',
    date: new Date(2024, 2, 26),
    dateVisual: 'Feburary, ongoing',
    links: [{
      type: 'itch',
      url: 'https://maggiemagius.itch.io/egg-related-videogame',
    }],
  },
  {
    name: 'trackmaker',
    description: 'A GUI chart editor for EX-XDRiVER',
    date: new Date(2024, 4, 26),
    links: [{
      type: 'git',
      url: 'http://github.com/oatmealine/trackmaker',
    }]
  },
  {
    name: 'nlw-api',
    description: 'An API for the Non-Listworthy Extreme Demons and Insane Demon Spreadsheet Google Sheets spreadsheets',
    date: new Date(2024, 2, 3),
    links: [{
      type: 'git',
      url: 'https://git.oat.zone/oat/nlw-api',
    }]
  },
  {
    name: 'NLW Integration',
    description: 'A Geode mod that integrates the Non-Listworthy Extreme Demon spreadsheet into level pages',
    date: new Date(2024, 2, 4),
    links: [{
      type: 'git',
      url: 'https://github.com/oatmealine/nlw-integration',
    }, {
      type: 'site',
      url: 'https://geode-sdk.org/mods/oatmealine.nlw_integration',
    }]
  },
  {
    name: 'gimmick!',
    description: 'A minimal NotITG v4.3.0 theme focused on developer simplicity and modern solutions',
    date: new Date(2024, 4, 7),
    dateVisual: 'April, ongoing',
    links: [{
      type: 'git',
      url: 'https://github.com/femboyindustries/gimmick-theme',
    }],
  }
];

export function renderProjects() {
  const container = document.createElement('div');
  container.className = 'projects';

  let lastYear = 0;
  for (const proj of projects.sort((a, b) => a.date.getTime() - b.date.getTime())) {
    if (proj.date.getFullYear() !== lastYear) {
      lastYear = proj.date.getFullYear();
      const sep = html(`${proj.date.getFullYear()}`);
      sep.className = 'seperator';
      container.appendChild(sep);
    }
    const project = document.createElement('details');
    
    project.innerHTML = `
      <summary class="label">
        <div class="name cyan">${proj.name}</div>
        <div class="date black">${proj.dateVisual || proj.date.toLocaleString('en-GB', { month: 'long' })}</div>
      </summary>
      <div class="open">
        <div class="description">${proj.description}</div>
        <div class="links">
          ${proj.links.map(link => `
            <div class="link">
              <a target="_blank" rel="noopener" href="${link.url}" class="white">${actuallyEscapeHTML(link.url)}</a>
              
            </div>
          `).join('\n')}
        </div>
      </div>
    `;
    project.className = 'project';
    container.appendChild(project);
  }

  return container;
}